import hero from '../../../Images/hero.svg'
import { FaArrowRight } from "react-icons/fa6";

export default function Header(props) {
  return (
    <div className="relative bg-gray-50">
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-6 pb-6 sm:pt-8 sm:pb-10 md:pt-10 md:pb-14 text-center lg:py-36 lg:text-left">
          <div className="px-3 lg:w-1/2 sm:px-8 xl:pr-14">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl relative bottom-4">
              <span className="hidden sm:block lg:hidden">{props.header1}</span>{' '}
              <span className="block sm:hidden lg:block">Your One-Stop Shop for</span>{' '}
              <span className="block sm:hidden lg:block"> </span>{' '}
              <span className="block text-violet-500 xl:inline">{props.header2}</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base font-normal text-gray-500 sm:text-base md:text-xl md:mt-5 md:max-w-3xl">
              {props.description1}
            </p>
            {/* <p className="mt-3 max-w-md mx-auto text-sm text-gray-400 sm:text-base md:text-xl md:mt-5 md:max-w-3xl">
              { props.description2 }
            </p> */}
            <div className="relative w-full h-64 sm:h-96 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-3/5 lg:h-full">
              <img
                className="absolute inset-0 w-full h-full object-cover"
                src={hero}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="mx-auto relative bottom-5 lg:bottom-0 max-w-7xl w-full mt-4 lg:mt-10 sm:flex sm:justify-center lg:justify-start px-3 sm:px-8">
          <div className="lg:relative lg:left-20 lg:bottom-20">
            <a
              href={props.buttonRedirect}
              className="w-3/4 mx-auto sm:w-auto shadow flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-violet-500 hover:bg-violet-600 md:py-4 md:text-lg md:px-10"
            >
              {props.buttonText}
              <FaArrowRight className='ml-1' size={18}></FaArrowRight>
            </a>
          </div>
        </div>
      </main>
    </div>

  )
}