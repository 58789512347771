export default function CTA(props) {
  return (
    <div className="bg-violet-500">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-6">
        <h2 className="text-3xl font-bold text-white sm:text-4xl">
          <span className="block">{ props.header_line1 }</span>
          <span className="block">{ props.header_line2 }</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-100">
          { props.description }
        </p>
        <a
          href={ props.buttonRedirect }
          className="mt-8 w-auto inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-bold rounded-full text-violet-600 bg-white hover:bg-violet-50 sm:w-auto"
        >
          { props.buttonText }
        </a>
      </div>
    </div>
  )
}