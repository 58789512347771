import Header from '../Components/Body/Header/MainHeader'
import Features from '../Components/Body/Sections/Features';
import CTA from '../Components/Body/Sections/CTA';
import Pricing from '../Components/Body/Sections/PricingFocus';
import HowDifferent from '../Components/Body/Sections/HowDifferent';

import { BiTrophy } from 'react-icons/bi'
import { FiPackage, FiCamera } from 'react-icons/fi'
import { MdHandshake, MdVerified, MdMessage } from 'react-icons/md'
import TestimonialSlider from '../Components/Body/Sections/TestimonialsSlider';

const features = [
  { name: 'Sourcing',
    header: 'Factory-Direct Pricing for Your Products.',
    description: 'We can help you source your exact current product or future product by scouring through our vast factory network, spanning across all categories, ensuring you find precisely what you need.',
    icon: BiTrophy
  },
  { name: 'Fulfillment',
    header: 'Seamless Fulfillment Solutions Tailored to Your Needs.',
    description: "Our fulfillment solutions offer a range of options once your product reaches our warehouse. From dropshipping to bulk orders, we've got you covered.",
    icon: FiPackage
  },
  { name: 'Content',
    header: 'Leverage a full-service content creation team.',
    description: 'Get elite custom content by working with our in-house team of professional photographers and graphic designers.',
    icon: FiCamera
  },
]

const plans = {
  first: {
    name: 'Build',
    free: false,
    price: {
      monthly: 19,
      yearly_per_month: 15,
      yearly_total: 180,
      yearly_discount: "20%"
    },
    features: [
      'Analytics dashboard',
      'Access to marketplace',
      'Access to product quotation',
      'Shipment tracking',
      'Email support'
    ]
  },
  second: {
    name: 'Grow',
    free: false,
    price: {
      monthly: 39,
      yearly_per_month: 31,
      yearly_total: 372,
      yearly_discount: "20%"
    },
    features: [
      'Everything included in Build',
      'Unlimited marketplace products',
      'Unlimited product quotes',
      'Bulk/samples ordering',
      'Live chat support',
    ]
  },
  third: {
    name: 'Scale',
    free: false,
    price: {
      monthly: 79,
      yearly_per_month: 62,
      yearly_total: 744,
      yearly_discount: "20%"
    },
    features: [
      'Everything included in Grow',
      'Exclusive products',
      'Exclusive features',
      'Dedicated account manager'
    ]
  }
}

const other_features = [
  {
    name: 'Dropshipping',
    description: '',
  },
  {
    name: 'Bulk Orders',
    description: '',
  },
  {
    name: 'Custom Content Creation',
    description: '',
  },
  {
    name: 'Product Development',
    description: '',
  },
  {
    name: 'Custom Packaging',
    description: '',
  },
  {
    name: 'Private Labeling & White Labeling',
    description: '',
  },
  // {
  //   name: 'Product Photography',
  //   description: '',
  // },
  // {
  //   name: 'Product Videography',
  //   description: '',
  // },
  // {
  //   name: 'Logo Design',
  //   description: '',
  // },
]

const howDifferences = [
  { name: 'Ease of Communication',
    header: 'Factory-Direct Pricing for Your Products.',
    description: 'Fulfill offers multiple US Account Managers to ensure seamless communication. Our fluent English-speaking account managers are always at your beck and call, ready to handle any challenges your project may present.',
    icon: MdMessage
  },
  { name: 'Trustworthy & Fair Pricing',
    header: 'Seamless Fulfillment Solutions Tailored to Your Needs.',
    description: "At Fulfill, we pride ourselves on transparent and fair pricing. By going directly to manufacturers, we eliminate the need for intermediaries like distributors or trade factories. Our small sourcing fees allow you to build your business sustainably without significantly impacting your profits.",
    icon: MdVerified
  },
  { name: 'Strong Factory Relationships',
    header: 'Leverage a full-service content creation team.',
    description: 'We maintain consistent communication with numerous partnered direct factories across various categories. Additionally, our strong relationships with top shipping companies enable us to offer favorable shipping rates to all countries.',
    icon: MdHandshake
  },
]

export default function Home() {
  return (
    <div className="min-h-screen">

      <Header
        header1="Your One-Stop Shop for"
        header2="Product Sourcing & Fulfillment."
        description1="White glove service with boots on the ground in China and factory-direct pricing. From sourcing to branding, Fulfill covers it all."
        description2="At Fulfill, we’re ready to revolutionize your entire business with just a few clicks. Are you?"
        buttonText="Start Saving Today "
        buttonRedirect="/contact"
      />
      
      {/* <Globe /> */}

      <Features
        title="Immediate Solutions"
        header=" Empowering features to boost your business, tailored for all sizes."
        description="We built Fulfill from the ground up to make life easier for entrepreneurs like you."
        features={features}
      />
      <TestimonialSlider />
      {/* <Explanation />
      <Details details={details} /> */}
      <Pricing 
        plans={plans}
        header="Additional solutions"
        description="No matter your business type, we have solutions tailored to fit your needs."
        features={other_features}
      />

      <HowDifferent headerLeft="How Fulfill" headerRight=" is Different" features={howDifferences} />

     
      {/* <TestimonialsWall></TestimonialsWall> */}

      <CTA 
        header_line1=" Ready to increase efficiencies and improve profit margins"
        header_line2=" with our solutions?"
        description="Elevate your business with Fulfill."
        buttonText="Contact Us"
        buttonRedirect="/contact"
      />

    </div>
  );
}