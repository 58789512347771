import illustration1 from '../../../Images/illustration1c.png'
import illustration2 from '../../../Images/illustration2b.png'
import illustration3 from '../../../Images/illustration3b.png'

import { BiTrophy } from 'react-icons/bi'
import { FiPackage, FiCamera } from 'react-icons/fi'

export default function Features(props) {
  return (
    <div className="relative bg-white sm:pb-8 pt-16 lg:pb-8">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-violet-500 uppercase">{ props.title }</h2>
        <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl">
          { props.header }
        </p>
        <p className="mt-5 mx-auto text-lg sm:text-xl text-gray-500">
          { props.description }
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            
              <div key={props.features[0].name} className="pt-6">
                <div className="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-violet-500 rounded-md shadow-lg">
                        <BiTrophy className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{props.features[0].name}</h3>
                    <p className="mt-5 text-base text-gray-500 pb-6">
                      {props.features[0].header}
                    </p>
                    <img
                      src={illustration1} 
                      alt=""
                    ></img>
                    <p className="mt-5 text-base text-gray-500 pt-2">
                      {props.features[0].description}
                    </p>
                  </div>
                </div>
              </div>

              <div key={props.features[1].name} className="pt-6">
                <div className="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-violet-500 rounded-md shadow-lg">
                        <FiPackage className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{props.features[1].name}</h3>
                    <p className="mt-5 text-base text-gray-500 pb-6">
                      {props.features[1].header}
                    </p>
                    <img
                      src={illustration2} 
                      alt=""
                    ></img>
                    <p className="mt-5 text-base text-gray-500 pt-2">
                      {props.features[1].description}
                    </p>
                  </div>
                </div>
              </div>

              <div key={props.features[2].name} className="pt-6">
                <div className="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-violet-500 rounded-md shadow-lg">
                        <FiCamera className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{props.features[2].name}</h3>
                    <p className="mt-5 text-base text-gray-500 pb-6 lg:whitespace-nowrap">
                      {props.features[2].header}
                    </p>
                    <img
                      src={illustration3} 
                      alt=""
                    ></img>
                    <p className="mt-5 text-base text-gray-500 pt-2">
                      {props.features[2].description}
                    </p>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
    </div>
  )
}
