// Layout.js
import React from 'react';
import Header from '../Components/Menu/Menu';
import Footer from '../Components/Footer/Footer'

const Layout = ({ children, showHeaderFooter = true }) => (
  <div>
    {showHeaderFooter && <Header />}
    {children}
    {showHeaderFooter && <Footer />}
  </div>
);

export default Layout;