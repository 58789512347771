import Header from './../../Components/Body/Header/ImageHeader'
import Blog from './../../Components/Body/Sections/BlogContent2';
import FeaturesListThreeCol from './../../Components/Body/Sections/FeaturesListThreeCol';
import CTA from './../../Components/Body/Sections/CTA';

import { MdOutlineFactory, MdOutlineWarehouse } from "react-icons/md";
import { PiPackage } from "react-icons/pi";
import { FaTiktok, FaAmazon } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";

import sourcing from '../../Images/back-sourcing2.jpg'

const features = [
  {
    name: 'Factory Direct Product Sourcing',
    description: "Our approach involves boots-on-the-ground brokering in China, where our team is physically present to negotiate directly with manufacturers. This hands-on approach allows us to secure the best pricing and ensure the highest quality for your products.",
    icon: MdOutlineFactory,
  },
  {
    name: 'Carrier integrations',
    description: "We partner directly with a variety of carriers, empowering our users to select their preferred shipping method, whether it's through express courier services, ocean freight, air freight, or any other fulfillment method that suits their business needs. We ensure transparency with real-time updates on shipping details, providing a seamless experience for all types of businesses.",
    icon: PiPackage,
  },
  {
    name: 'Automatic fulfillment in our own warehouse',
    description:
      'Because we built our own custom 20,000 sq. ft. fulfillment warehouse for handling orders for products that flow through China, you’ll never have to worry about wading through dozens of shipping options.',
    icon: MdOutlineWarehouse,
  },
  {
    name: 'Tik Tok Shop Owners',
    description: "We assist TikTok shop owners with sourcing products directly from manufacturers at competitive prices and managing ordering and shipping inventory to warehouses or 3PLs. Our dropshipping service includes expedited U.S. only tracking updates that comply with TikTok's terms. We're committed to finding new ways to support TikTok shop sellers with personalized solutions. Contact us for a customized plan if you're running on TT shop.",
    icon: FaTiktok,
  },
  {
    name: 'Amazon FBA',
    description: "We support Amazon FBA sellers by providing superior product sourcing that guarantees competitive pricing, helping secure bulk inventory orders for seamless shipping to warehouses or directly to Amazon. With deep expertise in Amazon's procedures, we ensure a smooth and efficient process, making us an easy and reliable partner to work with.",
    icon: FaAmazon,
  },
  {
    name: 'Brand or Business Owner',
    description:
      'We specialize in making a significant impact on any company, regardless of its size or industry. Our extensive range of services ensures that your business can thrive, especially if you rely on products sourced from China, a key manufacturing hub. We provide a competitive advantage by leveraging our expertise to benefit your operations. Our solutions streamline sourcing processes, effectively reducing costs. Following sourcing, you maintain full control over the fulfillment method, offering you flexibility and autonomy in managing your operations.',
    icon: IoBusiness
  },
]

export default function Content() {
  return (
    <div>

      <Header
          title="Solutions"
          header="Sourcing & Fulfillment"
          description="Fulfill's solution is designed for businesses of all sizes and operational methods. We find the best and most cost-effective direct manufacturers and offer fulfillment to your customers or fast delivery to your warehouse."
          color="bg-gray-800"
          image={sourcing}
      />
  
      <FeaturesListThreeCol features={features} />
      
      {/* <ImageShowcaseText /> */}

      <Blog />
      
      <CTA 
        header_line1="Ready to painlessly automate"
        header_line2="your order fulfillment?"
        description="Take your ecommerce to the next level with Fulfill—reach out to us today!"
        buttonText="Contact Us"
        buttonRedirect="/contact"
      />
      
    </div>
  );
}
