// Pages

import Home from './Pages/Home';
import SourcingFulfillment from './Pages/Features/SourcingFulfillment'
import Content from './Pages/Features/Content'
import ProductDevelopment from './Pages/Features/ProductDevelopment'
import Logo from './Pages/Features/Logo'
import Pricing from './Pages/Pricing';
import Privacy from './Pages/More/Privacy';
import Terms from './Pages/More/Terms';
import Contact from './Pages/Contact';
import FormSubmitted from './Pages/More/FormSubmitted';
import Layout from './Pages/Layout';

import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './Utils/ScrollToTop'
import usePageTracking from './Utils/PageTracking'

function App() {

  usePageTracking()

  return (
    <ScrollToTop>

      {/* <Menu /> */}
      <Switch>
        <Route path="/form-submitted">
          <Layout showHeaderFooter={false}>
            <FormSubmitted />
          </Layout>
        </Route>

        {/* Product */}

        <Route path="/features/sourcing-and-fulfillment">
          <Layout showHeaderFooter={true}>
            <SourcingFulfillment />
          </Layout>
        </Route>
        <Route path="/features/content">
          <Layout showHeaderFooter={true}>
            <Content />
          </Layout>
        </Route>

        <Route path="/features/logo">
          <Layout showHeaderFooter={true}>
            <Logo />
          </Layout>
        </Route>

        <Route path="/features/product-development">
          <Layout showHeaderFooter={true}>
            <ProductDevelopment />
          </Layout>
        </Route>

        {/* Pricing */}

        <Route path="/pricing">
          <Layout showHeaderFooter={true}>
            <Pricing />
          </Layout>
        </Route>

        {/* Contact */}

        <Route path="/contact">
          <Layout showHeaderFooter={true}>
            <Contact />
          </Layout>
        </Route>

        {/* More */}

        <Route path="/privacy">
          <Layout showHeaderFooter={true}>
            <Privacy />
          </Layout>
        </Route>
        <Route path="/terms">
          <Layout showHeaderFooter={true}>
            <Terms />
          </Layout>
        </Route>

        {/* Home */}

        <Route path="/">
          <Layout showHeaderFooter={true}>
            <Home />
          </Layout>
        </Route>

      </Switch>
      {/* <Footer /> */}

    </ScrollToTop>
  );
}

export default App;
