import Header from './../../Components/Body/Header/ImageHeader'
import ProductDevContent from './../../Components/Body/Sections/ProductDevContent';
import ProductDevDiagram from './../../Components/Body/Sections/ProductDevDiagram';
import CTA from './../../Components/Body/Sections/CTA';
import ProductDevContent2 from './../../Components/Body/Sections/ProductDevContent2';


import { MdOutlineFactory, MdOutlineWarehouse } from "react-icons/md";
import { PiPackage } from "react-icons/pi";
import { FaTiktok, FaAmazon } from "react-icons/fa";
import { IoBusiness } from "react-icons/io5";

import headerImage from '../../Images/back-product-dev.png'

const diagramInfos = [
  {
    name: 'Concept and Design',
    description: "We start by understanding your vision and reviewing your detailed designs, prototypes, and references.",
    icon: MdOutlineFactory,
  },
  {
    name: 'Sourcing',
    description: "We identify and partner with the best manufacturer for your project.",
    icon: PiPackage,
  },
  {
    name: 'Production Planning',
    description: "We plan the entire production process, reviewing all details with the factory to ensure efficiency and quality. We confirm with you that it meets your specifications and explain the associated costs, timeline, and process.",
    icon: MdOutlineWarehouse,
  },
  {
    name: 'Samples',
    description: "We express ship you a sample of the product for confirmation before proceeding with full production.",
    icon: FaTiktok,
  },
  {
    name: 'Manufacturing',
    description: "The factory begins production while our skilled team oversees the process to ensure your product meets the highest standards.",
    icon: FaAmazon,
  },
  {
    name: 'Quality Control & Delivery',
    description: "We perform rigorous quality checks to ensure your product meets all specifications. Finally, we manage the logistics to deliver your product on time, based on the fulfillment method of your choice.",
    icon: IoBusiness
  },
]

export default function Content() {
  return (
    <div>

      <Header
          title="Solutions"
          header="Product Development"
          description="At Fulfill, we understand the hurdles of bringing your ideas to life. That’s why we provide premier product development services and help you find trustworthy manufacturers for your projects. We guarantee complete confidentiality, ensuring your vision remains protected every step of the way."
          color="bg-gray-500"
          image={headerImage}
      />
  
      
      
      {/* <ImageShowcaseText /> */}

      <ProductDevContent />

      <ProductDevDiagram infos={diagramInfos} />

      <ProductDevContent2 />

      <CTA 
        header_line1="Why delay your project any longer?"
        header_line2=""
        description="Just send us an outline of your sourcing or product development needs, request a free quote, and we’ll get started immediately."
        buttonText="Contact Us"
        buttonRedirect="/contact"
      />
      
    </div>
  );
}
