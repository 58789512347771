import React, { useRef, useState, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { FaPlay, FaPause } from 'react-icons/fa';
import './VoiceMessageWidget.css';

const VoiceMessageWidget = ({ src, clickAction, barWidth = 3, options, video}) => {
  const waveformRef = useRef(null);
  const [waveSurfer, setWaveSurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (waveformRef.current) {
      const ws = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#969696',
        progressColor: '#7367F0',
        cursorWidth: 0,
        barWidth: barWidth,
        barHeight: 1,
        barRadius: 100,
        responsive: true,
        height: 32,
          // If true, normalize by the maximum peak instead of 1.0.
        normalize: true,
        // Use the PeakCache to improve rendering speed of large waveforms.
        partialRender: true,
        backend: "WebAudio",
        ...options
      });

      ws.load(src);

      ws.on('ready', () => {
        video && ws.setMuted(true);
        setDuration(ws.getDuration());
      });
      ws.on('finish', () => {
        setIsPlaying(false);
      });

      setWaveSurfer(ws);

      return () => {
        ws.destroy();
      };
    }
  }, [src, barWidth, options, video]);

  const togglePlayPause = () => {
    if (isPlaying) {
      waveSurfer.pause();
    } else {
      waveSurfer.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  function clickHandler() {
    togglePlayPause();
    clickAction && clickAction();
  }

  return (
    <div className="voice-message-widget">
      <div className="controls">
        <button onClick={clickHandler} className="play-pause-button">
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="waveform" ref={waveformRef}></div>
        <div className="duration">{formatTime(duration)}</div>
      </div>
    </div>
  );
};

export default VoiceMessageWidget;
