import React, { useRef } from 'react';
import { GrUpload } from "react-icons/gr";


const FileInput = ({ onChange, value, className, name, identifier }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.files);
    }
  };

  return (
    <div className={className}>
      <button
        type="button"
        onClick={handleClick}
        className={`relative inline-flex w-full justify-between px-4 py-2 text-base font-medium ${value.length > 0 ? "text-gray-900" : "text-gray-400"} bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        {value && value.length > 0
          ? `${Array.from(value).map(file => file.name).join(', ')}`
          : 'Upload Product Photos or References'}

        {value.length <= 0 && <GrUpload size={22} className='absolue right-0'/>}
      </button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        className="hidden"
        name={name}
        id={identifier}
        multiple // Permite seleção de múltiplos arquivos
      />
    </div>
  );
};

export default FileInput;
