export default function BlogContent() {
  return (
    <div className="relative pb-20 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mt-6 prose prose-lg text-gray-500 mx-auto">
          <h3><span className="text-violet-500 font-bold">Product Replication </span></h3>
          <p>
            Unlock competitive advantage with our Product Replication services. Just provide us with a link or sample, and our team in China will find a manufacturer to precisely duplicate the product, as long as it doesn’t have design patents. We will then express ship you samples to quality check before proceeding with production. This efficient solution helps you quickly bring similar products to market, often at a more affordable rate.
          </p>
          <h3><span className="text-violet-500 font-bold">Product Enhancement</span></h3>
          <p>
            Stay ahead with our Product Enhancement services. Whether it’s an existing market product or one you bring to us, we can modify it to make it superior and unique. Simply send us current market products or samples directly, and we’ll work with our factory partners to replicate or enhance them. We will then express ship you samples to quality check before proceeding with production. By making slight changes and adding standout features, we help you create a product with a distinct selling proposition, setting it apart from competitors. Leverage our expertise to elevate your product and achieve a competitive edge in the marketplace.
          </p>
          {/* <figure>
              <img
                className="w-full rounded-lg"
                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"
                alt=""
                width={1310}
                height={873}
              />
              <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption>
            </figure> */}
        </div>
      </div>
    </div>
  )
}
