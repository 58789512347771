export default function ProductDevDiagram(props) {
  return (
    <div className="py-24 bg-white">
      <div className="product-diagram-container bg-repeat sm:bg-no-repeat max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="mb-8 tracking-tight text-center text-xl font-bold">Simplified Breakdown of Our Process</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {props.infos.map((info, index) => (
            <div key={index} className="from-violet-500 to-violet-600 p-4 rounded-lg h-72 bg-gradient-to-l">
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-full text-violet-500 bg-white">
                  {/* <info.icon className="h-6 w-6" aria-hidden="true" /> */}
                  <p class="font-extrabold text-xl">{index += 1}</p>
                </div>
                <p className="mt-5 text-xl leading-6 font-extrabold text-white">{info.name}</p>
              </dt>
              <dd className="mt-2 text-base font-normal text-white">{info.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
