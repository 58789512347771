import React from 'react';

function MenuButton({isOpen, toggleMenu}) {
  return (
    <div className="relative">
      <button onClick={() => toggleMenu(!isOpen)} className="relative w-8 h-8 focus:outline-none">
        <span
          className={`one block absolute h-0.5 w-full bg-black transform transition duration-500 ease-in-out ${
            isOpen ? 'rotate-45' : '-translate-y-2'
          }`}
        />
        <span
          className={`block absolute h-0.5 w-full bg-black transform transition duration-500 ease-in-out ${
            isOpen ? 'opacity-0' : ''
          }`}
        />
        <span
          className={`block absolute h-0.5 w-full bg-black transform transition duration-500 ease-in-out ${
            isOpen ? '-rotate-45' : 'translate-y-2'
          }`}
        />
      </button>
    </div>
  );
}

export default MenuButton;
