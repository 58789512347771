import { BiBrain } from "react-icons/bi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { GrUpgrade } from "react-icons/gr";
const posts = [
  {
    title: 'New Product Creation',
    description: 'We can develop products from scratch, bringing your idea or vision to life, managing every step of the process from initial concept to the delivery of final production, while keeping you updated and incorporating your feedback.',
    icon: BiBrain
  },
  {
    title: 'Product Replication',
    description: 'Provide us with a sample or a link, and we will replicate the product precisely, ensuring quality and efficiency.',
    icon: HiOutlineDocumentDuplicate
  },
  {
    title: 'Product Enhancement',
    description: 'Send us existing market products or samples, and we will modify and enhance them to make them superior and unique.',
    icon: GrUpgrade
  },
]

export default function ProductDevContent() {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          {/* <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">From the blog</h2> */}
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Transform your ideas into reality with our expert product development services. From concept to completion, we manage the entire production process for you. Leveraging our advanced manufacturing knowledge, we partner with top-tier manufacturers and collaborate closely with their developers, engineers, and designers to seamlessly bring your product vision to life.
          </p>
        </div>
        <h2 className="text-xl tracking-tight font-bold text-gray-900 mt-12 text-center">We offer a range of services to suit your needs:</h2>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="bg-white flex items-center justify-center h-48 w-full">
                {/* <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" /> */}
                <div className="bg-violet-500 p-10 rounded-full shadow-sm">
                  <post.icon size={48} className="text-white"></post.icon>
                </div>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href={post.href} className="block mt-2">
                    <p className="text-xl text-center font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-20">
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Our skilled team in China excels in R&D and product creation, ensuring we will support any project you have planned and deliver outstanding results. Whether you need a completely new product, a replication of a product, or modifications to existing products, we have the expertise to make it happen. Leverage our comprehensive services to achieve a competitive edge in the marketplace.
          </p>
        </div>
      </div>
    </div>
  )
}