import image from '../../../Images/space1.JPG'

export default function BlogContent() {
    return (
      <div className="relative bg-white">
        <div className="py-16">
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 mb-4">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={image}
              alt=""
            />
          </div>
          <div className="lg:col-start-2 lg:pl-8 pt-100 lg:pt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-violet-500 font-semibold tracking-wide uppercase">Work with us</h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Our Sourcing & Fulfillment Process
              </h3>
              <p className="mt-8 prose prose-violet text-gray-500">
              With Fulfill, the first step is to provide you with a detailed quote for the products you currently sell or are interested in selling.
              </p>
              <div className="mt-5 prose prose-violet text-gray-500">

              <p>Just click the 'Contact Us' button below, and include all necessary information such as reference links and preferred shipping methods in the 'How can we help you?' box. You'll receive a quote within 24-48 hours, which includes a detailed report of your shipping options, delivery times, and carrier information.</p>

              <p>Upon receiving your input, our first step is to thoroughly evaluate top-tier factories specializing in the production of your required products. We will proactively engage with them via phone and in-person visits, ensuring we gather all essential details to seamlessly translate your needs into reality.
              </p>

              <p>All you need to decide on is shipping — and at Fulfill, that means choosing from our huge selection of integrated carriers depending on your business’s price points, margin, and delivery requirements.</p>

              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    )
}
  