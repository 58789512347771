export default function FormTextArea({placeholder, name, identifier, className}) {
  return (
    <textarea 
    name={name}
    placeholder={placeholder}
    id={identifier}
    rows={10}
    className={`${className} relative inline-flex w-full justify-between px-4 py-2 font-medium text-gray-900 placeholder-gray-400 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
    style={{fontSize: "16px", touchAction: "pan-x pan-y", resize: "none", appearance: "none"}}
    >
    </textarea>
  )
}