import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

const SelectDropdown = ({ selectedOption, onChange, options, name }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="relative inline-block text-left w-full z-10">
      <button
        type='button'
        name={name}
        value={selectedOption}
        onClick={toggleDropdown}
        className={`inline-flex w-full justify-between px-4 py-2 text-base font-medium ${selectedOption ? "text-gray-900" : "text-gray-400"} bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        {selectedOption ? selectedOption 
         : "Current monthly revenue"}
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="p-1">
            {options.map((option) => (
              <button
                type='button'
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  toggleDropdown();
                }}
                className={`block w-full text-left px-4 py-2 text-sm ${
                  selectedOption === option.value
                    ? 'bg-indigo-100 text-indigo-900'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                {option.label}
                {selectedOption === option.value && (
                  <span className="ml-2 text-indigo-600">&#x2713;</span>
                )}
              </button>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default SelectDropdown;
