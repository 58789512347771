import RequiredMark from "./RequiredMark";

export default function FormInput({ placeholder, name, identifier, required, className, type, errorMessage, refElement }) {
  return (
    <div>
      <div
        className={`${className} relative inline-flex w-full justify-between bg-white border border-gray-300 rounded-md shadow-sm`}
      >
        <input
          type={type}
          ref={refElement}
          id={identifier}
          name={name}
          required={false}
          placeholder={placeholder}
          style={{fontSize: "16px", appearance: "none"}}
          className="border-none text-base font-medium px-4 py-2 w-full rounded-md text-gray-900 placeholder-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        </input>
        {required && <RequiredMark className="absolute -right-2 -top-2" />}
      </div>
      {errorMessage && <p className="text-red-600 text-sm mt-0.5">{errorMessage}</p>}
    </div>
  )
}