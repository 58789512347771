export default function HowDifferent(props) {
  const myList = props.features.map((item, index) =>
    <div key={index} className="pt-6">
      <div className="flow-root h-full rounded-lg p-6 shadow-xl">
        <div>
          <div className="flex items-center">
            <div className="inline-flex items-center justify-center p-3 bg-violet-500 rounded-md shadow-lg">
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <h3 className="ml-2 text-lg font-semibold text-gray-900 tracking-tight">{item.name}</h3>
          </div>
          <p className="mt-5 text-base text-left text-black-500 pt-2">
            {item.description}
          </p>
        </div>
      </div>
    </div>
  )
  return (
    <div className="relative bg-white pb-16 pt-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-violet-500 uppercase">{ props.title }</h2>
        <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl">
          { props.headerLeft }
          <span className="text-violet-500">{ props.headerRight}</span>
        </p>
        <p className="mt-5 mx-auto text-xl text-gray-500">
          { props.description }
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {myList}
          </div>
        </div>
      </div>
    </div>
  )
}
