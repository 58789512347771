import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useRef } from 'react';
import Slider from 'react-slick';
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { RiInstagramFill, RiTiktokFill } from "react-icons/ri";
import VoiceMessageWidget from "../../Widgets/VoiceMessageWidget/VoiceMessageWidget";
import VideoMessageWidget from "../../Widgets/VideoMessageWidget/VideoMessageWidget";
import logoBlack from "../../../Images/logo-black-alt.png";
import "../../../styles/testimonials-slider.css";

const testimonials = [
  {
    name: "Hassan Bazzi",
    attribution: "E-Commerce Entrepreneur",
    picture: "/assets/hassan-bazzi.jpg",
    audio: "/assets/hassan-bazzi.webm",
    video: "/assets/hassan-bazzi.webm",
    info: "2024 - Present",
    socialMedia: {
      instagram: "@bazzihassan"
    }
  },
  {
    name: "Nicolas Abitbol",
    attribution: "E-Commerce Entrepreneur",
    picture: "/assets/nicolas-abitbol.png",
    audio: "/assets/nicolas-abitbol.mp4",
    info: "2024 - Present",
    socialMedia: {
      instagram: "@realnicoabitbol"
    }
  },
  {
    name: "Joe Ugenti",
    attribution: "Social Media Mogul",
    picture: "/assets/joe-ugenti.jpg",
    audio: `/assets/joe-ugenti.mp4`,
    info: "2020 - Present",
    socialMedia: {
      tiktok: "@joe.ugenti"
    }
  },
]

const TestimonialsSlider = () => {
  let sliderRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const sliderList = testimonials.map((item, index) =>
    <div key={index} className="slider-item relative">
      <div className="author-image relative flex justify-center">
        {item.video
          ? <VideoMessageWidget src={item.video} poster={item.picture} />
          : <img src={item.picture} alt={item.name}></img>
        }
        {!item.video && <VoiceMessageWidget src={item.audio} />}
      </div>
      <div className="flex justify-center">

      </div>
      <footer className="relative mt-6">
        <div className="author-info justify-between w-full">
          <div className="flex text-left">
            <span>
              <strong className="text-xl">{item.name}</strong>
              <p className="attribution text-lg">{item.attribution}</p>
              <p className="text-base mt-1">{item.info}</p>
            </span>
          </div>
          {/* <div className="flex flex-row-reverse relative w-full justify-center sm:justify-end sm:w-auto gap-8 sm:gap-0 sm:flex-col items-center sm:items-end text-right">
            <p className="info-rating flex gap-1 items-center justify-end absolute right-0 sm:relative"><FaStar color='#7367f0' />(5.0)</p>
            <p>{item.info}</p>
          </div> */}
        </div>
        <div className="flex justify-start mt-6">
          {
            item.socialMedia.tiktok
            && <a href={`https://tiktok.com/${item.socialMedia.tiktok}`} className="flex gap-1 items-center hover:text-violet-500 transition-colors">
              <RiTiktokFill size={28}></RiTiktokFill>
            </a>
          }
          {
            item.socialMedia.instagram
            && <a href={`https://instagram.com/${item.socialMedia.instagram.slice(1)}`} className="flex gap-1 items-center hover:text-violet-500 transition-colors">
              <RiInstagramFill size={28}></RiInstagramFill>
            </a>
          }
        </div>
      </footer>
    </div>
  )

  return (
    <div className="relative bg-white sm:pb-8 pt-16 lg:pb-16">
      <div className="mx-auto max-w-md px-4 text-left sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-4xl font-bold text-gray-900 tracking-tight relative">
          What Our Clients Say About
          <img src={logoBlack} alt="fulfill black logo" width="96px" className="inline ml-2 mb-2.5"></img>
        </h2>
        <div className="flex justify-end gap-4 mt-10">
          <IoIosArrowDropleft className="cursor-pointer hover:text-violet-500" size={48} onClick={() => sliderRef.slickPrev()} />
          <IoIosArrowDropright className="cursor-pointer hover:text-violet-500" size={48} onClick={() => sliderRef.slickNext()} />
        </div>
      </div>
      <div className="w-full md:pl-20 pl-4 text-center mt-4">
        <div className="slider-container">
          <Slider ref={slider => { sliderRef = slider; }} {...settings}>
            {sliderList}
          </Slider>
        </div>
      </div>
    </div>

  );
};

export default TestimonialsSlider;