import { Fragment, useRef, useState, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { CameraIcon } from '@heroicons/react/outline'
import { FiPackage } from 'react-icons/fi'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { AiOutlineProject } from "react-icons/ai";
import { Link, NavLink } from 'react-router-dom';
import MenuButton from './MenuButton'

import logo from '../../Images/logo.png'
import logoWhite from '../../Images/logo-white-500.png'

const solutions = [
  {
    name: 'Sourcing & Fulfillment',
    description: 'Learn more about our automated fulfillment solution.',
    href: '/features/sourcing-and-fulfillment',
    icon: FiPackage,
  },
  {
    name: 'Content Creation',
    description: 'Discover how you can easily create high-quality content for your store.',
    href: '/features/content',
    icon: CameraIcon,
  },
  {
    name: 'Product Development',
    description: 'Turn your ideas into market-ready products with our expert development services.',
    href: '/features/product-development',
    icon: AiOutlineProject,
  }
  // {
  //   name: 'Logo Design',
  //   description: "Find out more about how we’ll help you end up with the perfect custom logo.",
  //   href: '/features/logo',
  //   icon: LightBulbIcon,
  // },
  // {
  //   name: 'Automations',
  //   description: 'Build strategic funnels that will drive your customers to convert',
  //   href: '/features/automations',
  //   icon: RefreshIcon,
  // },
]
const callsToAction = [
  // { name: 'Sign Up for Free', href: 'https://app.fulfill.me/register', icon: BadgeCheckIcon },
  // { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  // {
  //   name: 'Help Center',
  //   description: 'Get all of your questions answered in our forums or contact support.',
  //   href: '#',
  //   icon: SupportIcon,
  // },
  // {
  //   name: 'Guides',
  //   description: 'Learn how to maximize our platform to get the most out of it.',
  //   href: '#',
  //   icon: BookmarkAltIcon,
  // },
  // {
  //   name: 'Events',
  //   description: 'See what meet-ups and other events we might be planning near you.',
  //   href: '#',
  //   icon: CalendarIcon,
  // },
  // { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon },
]
// const recentPosts = [
//   { id: 1, name: 'Boost your conversion rate', href: '#' },
//   { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
//   { id: 3, name: 'Improve your customer experience', href: '#' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Menu() {

  const productMenuRef = useRef();

  let [isOpen, setIsOpen] = useState(false);

  const handleMenuButtonClick = (open) => {
    setIsOpen(open);
  }

  useEffect(() => {
    if (isOpen) {
      //const scrollTop = window.scrollY || document.documentElement.scrollTop;
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
      window.onscroll = function () {
        window.scrollTo(0, 0);
      }
    } else {
      document.body.style.overflow = "visible";
      window.onscroll = () => { };
    }
  })

  return (
    <Popover className="relative bg-gray-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6" style={{touchAction: "pan-x pan-y"}}>
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">Fulfill</span>
                  <img
                    className="h-6 w-auto sm:h-7"
                    src={logo}
                    alt="Fulfill Logo"
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                {/* <button 
                  className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                  onClick={() => {setIsOpen(true)}}
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-8 w-8" aria-hidden="true" />
                </button> */}
                <MenuButton isOpen={isOpen} toggleMenu={handleMenuButtonClick}></MenuButton>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-gray-50 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none'
                        )}
                        ref={productMenuRef}
                      >
                        <span>Services</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-900'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-20 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {solutions.map((item) => (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  onClick={() => productMenuRef.current?.click()}
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-violet-500" aria-hidden="true" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                              {callsToAction.map((item) => (
                                <div key={item.name} className="flow-root">
                                  <Link
                                    to={item.href}
                                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                  >
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="ml-3">{item.name}</span>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <NavLink
                  to="/contact"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  activeClassName="text-gray-900"
                >
                  Contact Us
                </NavLink>
              </Popover.Group>

            </div>
          </div>

          <Transition
            show={isOpen}
            as={Fragment}
            enter="duration-500 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              focus
              static
              className="fixed max-h-screen top-0 inset-x-0 z-20 transition transform  origin-top-right md:hidden overflow-hidden"
            >
              <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-violet-500" style={{ height: "100dvh", touchAction: "pan-x pan-y" }}>
                <div className="pt-5 pb-6 px-5" style={{ height: "90%" }}>
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-6 w-auto -ml-0.5 -mt-0.5"
                        src={logoWhite}
                        alt="Fulfill Logo"
                      />
                    </div>
                    <div className="-mr-3">
                      {/* <button
                          className="rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                          onClick={() => setIsOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-8 w-8" aria-hidden="true" />
                        </button> */}
                      <MenuButton isOpen={isOpen} toggleMenu={handleMenuButtonClick}></MenuButton>
                    </div>
                  </div>
                  <div className="mt-6 flex flex-col justify-center" style={{ height: "90%" }}>
                    <nav className={`flex flex-col gap-8 ${isOpen ? 'animate-menuNavUp' : 'animate-menuNavDown'}`}>
                      {solutions.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="cursor-pointer -m-3 p-3 flex justify-center items-center rounded-md hover:bg-gray-50"
                          onClick={() => setIsOpen(false)}
                        >
                          {/* <item.icon className="flex-shrink-0 h-6 w-6 text-violet-500" aria-hidden="true" /> */}
                          <span className="ml-3 text-3xl font-bold text-white">{item.name}</span>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="px-5 bg-violet-500">
                  <div className="flex justify-center">
                    <Link
                      to="/contact"
                      className={
                        `w-auto relative bottom-20 cursor-pointer text-xl text-center font-bold text-white bg-black shadow-md hover:text-gray-70 px-8 py-2 rounded-full
                          ${isOpen ? 'animate-menuContactButtonUp' : 'animate-menuContactButtonDown'}
                          `}
                      onClick={() => setIsOpen(false)}
                    >
                      Contact Us
                    </Link>

                    {/* <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Docs
                      </a> */}

                    {resources.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
