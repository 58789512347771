import ContactForm from "../Components/Body/Sections/ContactForm/ContactForm";
import logo from '../Images/logo.png';
import logoWhite from "../Images/logo-white.png"
import { IoMdCheckmarkCircle } from "react-icons/io";
import { BiSolidMessageRounded } from "react-icons/bi";

const workWithTexts = [
  "Comprehensive White Glove Services for E-Commerce Business Owners",
  "Direct Access to US Account Managers and Expert Chinese Advisors",
  "Our Team Continues to Ship During Chinese New Year",
  "Competitive Shipping Rates for Cost-Effective Deliveries",
  "Product Savings Through Direct Sourcing from Factories"
]


export default function Contact() {
  const metaViewport = document.querySelector('meta[name="viewport"]');

  if (metaViewport) {
    metaViewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover');
  } else {
    console.log('Meta tag viewport não encontrada.');
  }


  return (
    <main style={{ touchAction: "pan-x pan-y" }}>
      <div className="bg-gray-50 text-center pt-6 pb-4 px-4" style={{ touchAction: "pan-x pan-y" }}>
        <h1 className="text-4xl font-bold">Start <span className="text-violet-500">Saving</span></h1>
        <h1 className="text-4xl font-bold flex justify-center items-center">
          With
          <span className="ml-1">
            <img src={logo} width="96px" alt="Fulfill logo"></img>
          </span>
        </h1>
        <div className="bg-gray-50 pt-6 sm:mx-auto max-w-xl">
          <p className="text-gray-600 text-center">
            Whether you're a growing startup or an established business, welcome to how easy working with China really should be.
            Experience expert white glove services that elevate your business operations.
          </p>
        </div>
      </div>
      <div className="py-6 px-4 mx-auto max-w-xl">
        <ContactForm />
        <hr className="mt-10"></hr>
        <div className="mt-10 pt-6 pb-8 px-4 bg-violet-500 rounded-xl flex flex-col items-center">
          <div className="w-5/6 sm:w-3/5">
            <h2 className="text-white font-bold text-xl flex justify-center items-center gap-3">
              Work with
              <span>
                <img src={logoWhite} width={148} alt="Fulfill white logos"></img>
              </span>
            </h2>
            <ul className="list-none mt-8">
              {workWithTexts.map((text, index) => {
                return (
                  <li key={index} className="flex items-start relative mb-3">
                    <IoMdCheckmarkCircle color="white" size={28} className="absolute left-0 top-0"></IoMdCheckmarkCircle>
                    <p className="ml-8 font-bold text-white">{text}</p>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-10 w-full text-white" style={{ backgroundColor: "#303044", touchAction: "pan-x pan-y" }}>
        <div className="mx-auto max-w-xl py-8 px-8">
          <div className="flex justify-center">
            <div className="w-12 h-12 flex justify-center items-center rounded-full bg-white" style={{ boxShadow: "0 0 2px white" }}>
              <BiSolidMessageRounded className="text-violet-500" size={18}></BiSolidMessageRounded>
            </div>
          </div>
          <div className="mt-6">
            <p>Questions?</p>
            <p className="mt-3">For general inquiries, please email</p>
            <a href="mailto:jack@fulfill.me" className="font-bold underline">jack@fulfill.me</a>
          </div>
        </div>
      </div>
    </main>
  );
}
